* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
body {
    width: 100%;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
a {-webkit-tap-highlight-color:transparent;text-decoration: none;color: inherit;}
li {list-style: none;}
img {-webkit-tap-highlight-color:transparent;max-width: 100%;}
:focus {
    outline:0;
    -webkit-tap-highlight-color:transparent
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#__vconsole .vc-switch {
    top: .76em !important;
    bottom: unset !important;
}

